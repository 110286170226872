import axios from 'src/app-axios';
import Constants from 'src/constants';

export const jobs = {
    namespaced: true,
    state: {
        paginatedJobs: [],
        joblist: [],
        jobDetails: [],
        jobComments: [],
        jobRequests: [],
    },
    getters: {
        getPaginatedJobs (state) {
          return state.paginatedJobs;
        },
        getJobList (state) {
            return state.joblist;
        },
        getJobDetails (state) {
          return state.jobDetails;
        },
        getJobComments (state) {
          return state.jobComments;
        },
        getJobRequest (state) {
          return state.jobRequests;
        },
    },
    mutations: {
        setJobs (state, data) {
            state.joblist = data.data;
        },
        setPaginatedJobs (state, data) {
          state.paginatedJobs = data;
      },
        setJobDetails (state, data) {
          state.jobDetails = data;
        },
        setJobComments (state, data) {
          state.jobComments = data.data;
        },
        setJobRequests (state, data) {
          state.jobRequests = data;
        },
    },
    actions: {
        async fetchPaginatedJobs ({ dispatch, commit }, payload) {
          const url = `api/projects/${payload.projectId}/jobs`;
          try {
              const jobs = await axios.get(url, payload.params);
              commit('setPaginatedJobs', jobs.data);
          } catch (exception) {
              dispatch('alert/onAlert', {
                  message: 'Something went wrong in getting the jobs list.',
                  type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
              throw exception;
          }
      },
        async fetchJobsByProject ({ dispatch, commit }, payload) {
            const url = `api/projects/${payload.projectid}/jobs`;
            try {
              const response = await axios.get(url, payload);
              commit('setJobs', response.data);
            } catch (exception) {
              dispatch('alert/onAlert', {
                message: 'Something went wrong in getting the job lists.',
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            }
          },
          async createJob ({ dispatch }, payload) {
            const url = 'api/jobs';
            try {
              const response = await axios.post(url, payload);
              return response.data;
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in creating this project.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
        },
        async JobDetails ({ dispatch, commit }, payload) {
          const url = `api/jobs/${payload.jobId}`;
          try {
            const response = await axios.get(url, payload);
            commit('setJobDetails', response.data);
          } catch (exception) {
            dispatch('alert/onAlert', {
              message: 'Something went wrong in getting the job lists.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
          }
        },
        async updateJob ({ dispatch }, payload) {
          const url = `api/jobs/${payload.jobId}`;
          try {
            await axios.put(url, payload);
          } catch (exception) {
              dispatch('alert/onAlert', {
                  message: 'Something went wrong in update this job.',
                  type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
              throw exception;
          }
      },
      async deleteJob ({ dispatch }, payload) {
        const url = `api/jobs/${payload.jobId}`;
          try {
            await axios.delete(url, payload);
          } catch (exception) {
              dispatch('alert/onAlert', {
                  message: 'Something went wrong in deleting this job.',
                  type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
              throw exception;
          }
      },
      async addJobComment ({ dispatch }, payload) {
        const url = `api/jobs/${payload.formData.get('jobId')}/comments`;
            try {
              await axios.post(url, payload.formData);
            } catch (exception) {
                dispatch('alert/onAlert', {
                    message: 'Something went wrong in add comment.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                throw exception;
            }
      },
      async fetchJobComments ({ dispatch, commit }, payload) {
        const url = `api/jobs/${payload.jobId}/comments`;
        try {
          const response = await axios.get(url, payload);
          commit('setJobComments', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the comments.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async fetchJobsRequests ({ dispatch, commit }, payload) {
        const url = `api/projects/${payload.projectId}/job-requests`;
        try {
          const response = await axios.get(url, payload.params);
          commit('setJobRequests', response.data);
        } catch (exception) {
          dispatch('alert/onAlert', {
            message: 'Something went wrong in getting the job requests.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        }
      },
      async createRequest ({ dispatch }, payload) {
        const url = `api/jobs/${payload.jobId}/job-requests`;
        try {
          await axios.post(url, payload);
        } catch (exception) {
            dispatch('alert/onAlert', {
                message: 'Something went wrong in creating job request.',
                type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            throw exception;
        }
    },
    async updateRequest ({ dispatch }, payload) {
      const url = `api/jobs/job-requests/${payload.reqId.id}`;
      try {
        await axios.put(url, payload);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in update this job.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
    },
};
